




















import { Vue, Component, Emit, PropSync } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @PropSync('show')
  innerShow!: boolean;

  remarks = '';

  @Emit('input')
  handleClickConfirm() {
    this.innerShow = false;
    return this.remarks;
  }
}
