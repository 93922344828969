

















import { GetPromiseReturn } from '@/api/type/tool';
import { getCouponUsableList } from '@/api/user/coupon';
import CouponItem from '@/views/mine/coupon/comm/CouponItem.vue';
import { isEmpty } from 'lodash';
import { Vue, Component, Inject, PropSync, Emit } from 'vue-property-decorator';
import confirmOrder from '../confirm-order.vue';
type IData = GetPromiseReturn<typeof getCouponUsableList>['payload'];
@Component({
  name: '',
  components: { CouponItem }
})
export default class extends Vue {
  @PropSync('show')
  innerShow!: boolean;

  couponList: IData = [];

  selectedId: IData[number]['couponId'] = -1;

  @Inject()
  createOrderInfo!: confirmOrder['createOrderInfo'];

  @Emit('select')
  onSelect(item: IData[number]) {
    this.innerShow = false;
    if (item.couponId && item.usable) {
      this.selectedId = item.couponId;
      return item;
    }
    this.selectedId = -1;
    return {};
  }

  async created() {
    const { payload } = await getCouponUsableList(this.createOrderInfo.map(item => ({ skuId: item.skuId, num: item.amount })));
    this.couponList = payload;
    const item = payload.find(item => item.usable);
    if (!isEmpty(item) && item) {
      this.onSelect(item);
    }
  }
}
