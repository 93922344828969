/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-29 17:08:26
 * @FilePath: /mall-front-static/src/api/user/coupon.ts
 * @Description:
 *
 */
import request from '@/utils/request';
import { getToken } from '@/utils/storage';
import { CreateOrderSkuItem } from '../pay';
import { IRequestResult } from '../type/tool';

// 获取可用优惠券
export function getCouponUsableList(data: CreateOrderSkuItem[]): Promise<
  IRequestResult<
    {
      /* 定向券图片 */
      defineImage: string;
      /* 优惠券id */
      couponId: number;
      /* 券状态 */
      couponState: number;
      /* 营销id */
      defineId: number;
      expiredTime: string;
      defineName: string;
      defineRemark: string;
      couponPassword: string;
      couponNumber: string;
      usable: boolean;
      /* 以减金额 */
      consumePrice: number;
      /* 优惠券规则 */
      useRule: {
        discountLimit: number;
        discountMax: number;
        /* 1 抵扣券 2 满减券 3 定向券 */
        discountType: number;
      };
      useScope: {
        detailList: {
          /* -1 全范围 1 商品分类 2 商品ID 3 规格ID 4 一级分类 5 标签信息  */
          scopeType: number;
        }[];
        fixedCouponSkuId: number;
        fixedCouponSpuId: number;
      };
    }[]
  >
> {
  return request.post('/marketing/coupon/front/usableList', data, { hideMsg: true });
}

// 自动查询是否发券
export function autoSendCoupon() {
  if (getToken()) {
    return request.post('/marketing/coupon/front/autoSendCoupon', {}, { hideMsg: true });
  }
}
